<template>
  <div class="container" style="margin-top:150px;">
    <div class="va-card ">
      <div style="parding:10px; margin:40px;">
        <div class="row">
          <div class="col-lg-12">
            <div class="header_section">
              <div class="">
                <router-link :to="{ name: 'createmerchant'}">
                  <button class="btn btn-default custom_btn" >
                    <i class="fa fa-plus"></i> New Merchant
                  </button>
                </router-link>

                <router-link :to="{ name: 'groups'}">
                  <button class="btn ml-4 btn-default custom_btn" >
                    Merchant Groups <i class="glyphicon glyphicon-arrow-right"></i>
                  </button>
                </router-link>
              </div>

            </div>
          </div>
        </div>
      </div> 
      <div class="row">
        <div class="col-lg-12">
          <div class="table_section">
            <table class="table  table-hover">
              <thead>
                <tr>
                  <th>#</th>
                  <th scope="col">Name</th>
                  <th scope="col">POS Name</th>
                  <th scope="col">Status</th>
                  <th scope="col">logo</th>
                </tr>
              </thead>
              <tbody v-if="!loading">
                <tr v-for="merchant in merchants" :key="merchant.id">
                  <td>
                    {{ merchant.id }}
                  </td>
                  <td>
                    <router-link :to="{ name: 'updatemerchant', params: { id:merchant.id }}">
                      {{merchant.name}}
                    </router-link>
                  </td>
                  <td>{{merchant.pos_name}}</td>
                  <td>
                    <span :class="[{'active':merchant.status==1 },{'disabled': merchant.status == 0}]"><strong>{{ merchant.status == 1 ? "Published" : "Unpublished" }}</strong></span>
                  </td>
                  <td>
                    <img :src="merchant.logo" :alt="merchant.name" border=3  width=68>
                  </td>
                </tr>  
              </tbody>
              <tfoot v-if="!loading">
                <tr>
                  <td colspan="8">
                    <div id="pagination"  v-if="totalPages > 1">
                      <ul class="showItems">
                        <li>Show Items:
                          <select @change="onChangeRecordsPerPage" v-model="recordsPerPage">
                            <option :value="10">10</option>
                            <option :value="20">20</option>
                            <option :value="30">30</option>
                            <option :value="40">40</option>
                            <option :value="50">50</option>
                          </select>
                        </li>
                            
                      </ul>
                      <Pagination :total-pages="totalPages" :per-page="recordsPerPage" :current-page="page" @pagechanged="onPageChange" />

                    </div>
                          
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
          <div v-if="loading"  class="loading-cover">
            <div class="loader"> </div>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import ReusableDataMixins from '@/mixins/ReusableDataMixins';
import useVuelidate from '@vuelidate/core';
import Pagination from '@/pages/pagination/pagination.vue';

export default {
    components: {
        Pagination,
    },
    setup() {
        return { v$: useVuelidate() }
    },
    data() {
        return {
            merchants: [],
            page: 1,
            totalPages: 0,
            totalRecords: 0,
            recordsPerPage: 10,
            loading: false,
            enterpageno: '',
            spinner: "<i class='fa fa-spinner fa-spin '></i> Loggin in...",
            
        }
    },
    created:  async function () {
        this.getMerchants()
    },
    methods: {
        async getMerchants() {
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            const url = `${this.dashBaseUrl}/merchants`;
            this.loading = true;
            this.axios.get(url, config).then((response) => {
                if (response.status === 200) {
                    this.loading = false;
                    this.merchants = response.data.data;
                }
            }).catch((err) => {
                console.log('error', err);
            })

        },
        onPageChange(page) {
            this.page = page
            this.getMerchants();
        },
        onChangeRecordsPerPage() {
            this.getMerchants();
            // ds
        },
        
    },
    mixins: [ReusableDataMixins]
}
</script>
